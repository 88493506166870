import PropTypes from 'prop-types';

// material-ui
import { List, ListItemButton, ListItemIcon, ListItemText, Switch, CircularProgress } from '@mui/material';

// assets
import { Logout } from 'iconsax-react';

// ==============================|| HEADER PROFILE - PROFILE TAB ||============================== //

const ProfileTab = ({ handleLogout, faState, handleToggle2Fa, loading }) => {
  return (
    <List component="nav" sx={{ p: 0, '& .MuiListItemIcon-root': { minWidth: 32 } }}>
      {/* <ListItemButton sx={{ '.MuiSwitch-root': { marginY: 0, marginLeft: 0 } }} onClick={handleToggle2Fa}>
        <Switch disabled={loading} checked={faState === true} />
        <ListItemText primary="Toggle 2FA" />
        <CircularProgress size={16} sx={{ visibility: loading ? 'visible' : 'hidden' }} />
      </ListItemButton> */}
      <ListItemButton onClick={handleLogout}>
        <ListItemIcon>
          <Logout variant="Bulk" size={18} />
        </ListItemIcon>
        <ListItemText primary="Logout" />
      </ListItemButton>
    </List>
  );
};

ProfileTab.propTypes = {
  handleLogout: PropTypes.func,
  faState: PropTypes.bool,
  handleToggle2Fa: PropTypes.func,
  loading: PropTypes.bool
};

export default ProfileTab;
