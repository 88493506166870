import { useEffect } from 'react';
import PropTypes from 'prop-types';
import { useNavigate } from 'react-router-dom';

const VerificationGuard = ({ children }) => {
  const navigate = useNavigate();
  useEffect(() => {
    const token = sessionStorage.getItem('serviceTokenTemp');
    const qrCode = JSON.parse(sessionStorage.getItem('qrCode'));
    if (!token || !qrCode) {
      sessionStorage.removeItem('serviceTokenTemp');
      sessionStorage.removeItem('qrCode');
      navigate('/auth/login');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return children;
};

VerificationGuard.propTypes = {
  children: PropTypes.node
};

export default VerificationGuard;
