import { Navigate, useRoutes } from 'react-router-dom';

// project-imports
import CommonLayout from 'layout/CommonLayout';
import ComponentsRoutes from './ComponentsRoutes';
import LoginRoutes from './LoginRoutes';
import MainRoutes from './MainRoutes';
import AdminRoutes from './AdminRoutes';

// ==============================|| ROUTES RENDER ||============================== //

export default function ThemeRoutes() {
  return useRoutes([
    {
      path: '/',
      element: <CommonLayout layout="landing" />,
      children: [
        {
          path: '/',
          element: <Navigate to="/login" replace />
        }
      ]
    },
    LoginRoutes,
    ComponentsRoutes,
    MainRoutes,
    AdminRoutes
  ]);
}
