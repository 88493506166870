import { lazy } from 'react';

// project-imports
import MainLayout from 'layout/MainLayout';
import AuthGuard from 'utils/route-guard/AuthGuard';
import Loadable from 'components/Loadable';
import AdminGuard from 'utils/route-guard/AdminGuard';

const Dashboard = Loadable(lazy(() => import('pages/dashboard/default')));

//admin - tabs
const ApiKeys = Loadable(lazy(() => import('pages/adminTabs/apiKeys')));
const Prompt = Loadable(lazy(() => import('pages/adminTabs/Prompt/Prompt')));
const User = Loadable(lazy(() => import('pages/adminTabs/User/Users')));
const Organization = Loadable(lazy(() => import('pages/adminTabs/Organization/Organizations')));
const Requests = Loadable(lazy(() => import('pages/adminTabs/Request/Requests')));
const UserConsultations = Loadable(lazy(() => import('pages/adminTabs/UserConsultation/UserConsultations')));
const OrgConsultations = Loadable(lazy(() => import('pages/adminTabs/Organization/OrgConsultataions')));
const AllConsultations = Loadable(lazy(() => import('pages/adminTabs/UserConsultation/AllConsultations')));
const ConsultationHistory = Loadable(lazy(() => import('pages/adminTabs/UserConsultation/ConsultationHistory')));
const Feedback = Loadable(lazy(() => import('pages/adminTabs/Feedback/Feedbacks')));
const ArchivedCons = Loadable(lazy(() => import('pages/adminTabs/Archive/ArchivedCons')));
const ArchivedPrompts = Loadable(lazy(() => import('pages/adminTabs/Archive/ArchivedPrompts')));

const AdminRoutes = {
  path: '/',
  children: [
    {
      path: '/',
      element: (
        <AuthGuard>
          <AdminGuard>
            <MainLayout />
          </AdminGuard>
        </AuthGuard>
      ),
      children: [
        {
          path: 'admin',
          children: [
            {
              path: 'dashboard',
              element: <Dashboard />
            },
            {
              path: 'apiKeys',
              element: <ApiKeys />
            },
            {
              path: 'prompts',
              element: <Prompt />
            },
            {
              path: 'users',
              element: <User />
            },
            {
              path: 'consultations/:id',
              element: <UserConsultations />
            },
            {
              path: 'consultation-history/:id',
              element: <ConsultationHistory />
            },
            {
              path: 'orgConsultations/:orgName',
              element: <OrgConsultations />
            },
            {
              path: 'feedbacks',
              element: <Feedback />
            },
            {
              path: 'archived-consultations',
              element: <ArchivedCons />
            },
            {
              path: 'archived-prompts',
              element: <ArchivedPrompts />
            },
            {
              path: 'organizations',
              element: <Organization />
            },
            {
              path: 'requests',
              element: <Requests />
            },
            {
              path: 'allConsultations',
              element: <AllConsultations />
            },
          ]
        }
      ]
    }
  ]
};

export default AdminRoutes;
