// third-party
import { createSlice } from '@reduxjs/toolkit';

// project-imports
import axiosService from 'utils/axios';
import { dispatch } from '../index';

// ----------------------------------------------------------------------

const initialState = {
  error: null,
  prompts: [],
  loading: false
};

// ==============================|| SLICE - CART ||============================== //
const slice = createSlice({
  name: 'prompt',
  initialState,
  reducers: {
    // HAS ERROR
    hasError(state, action) {
      state.error = action.payload;
    },

    setPrompts(state, action) {
      state.prompts = action.payload;
    },
    setLoading(state, action) {
      state.loading = action.payload;
    }
  }
});

// Reducer
export default slice.reducer;

export function getPrompt() {
  return async () => {
    try {
      dispatch(slice.actions.setLoading(true));
      const response = await axiosService.get('/prompt/all');
      dispatch(slice.actions.setPrompts(response.data));
      dispatch(slice.actions.setLoading(false));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function updatePrompt(data) {
  return async () => {
    try {
      const reponse = await axiosService.put('/prompt/update', data);
      return reponse.data;
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function createPrompt(data) {
  return async () => {
    try {
      const reponse = await axiosService.post('/prompt/create', data);
      return reponse.data;
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function deletePrompt(promptId) {
  return async () => {
    try {
      const reponse = await axiosService.delete(`/prompt/delete/${promptId}`);
      return reponse.data;
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function addAdditionalPrompts(id, data) {
  return async (dispatch) => { 
    try {
      const response = await axiosService.put(`/user/additionalPrompt/${id}`, data);
      return response.data;
    } catch (error) {
      dispatch(slice.actions.hasError(error));
      throw error;
    }
  };
}

export function getDeletedPrompts() {
  return async () => {
    try {
      dispatch(slice.actions.setLoading(true));
      const response = await axiosService.get('/prompt/get-deleted-prompts');
      dispatch(slice.actions.setPrompts(response.data));
      dispatch(slice.actions.setLoading(false));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function restorePrompt(promptId) {
  return async () => {
    try {
      const reponse = await axiosService.put(`/prompt/restore/${promptId}`);
      return reponse.data;
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}