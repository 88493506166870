
// ==============================|| MENU ITEMS - API ||============================== //

export const Menu = () => {

  const menuList = {
    //
  };

  return menuList;
};
