import { useLocation, useNavigate } from 'react-router';
import PropTypes from 'prop-types';
import { useEffect } from 'react';

const AdminGuard = ({ children }) => {
  const navigate = useNavigate();
  const location = useLocation();
  const role = sessionStorage.getItem('role');

  useEffect(() => {
    if (role !== 'admin') {
      navigate('/', {
        state: {
          from: location.pathname
        },
        replace: true
      });
    }
  }, [role, navigate, location]);

  return children;
};

AdminGuard.propTypes = {
  children: PropTypes.node
};

export default AdminGuard;
