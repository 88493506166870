// assets
import {
  Message2,
  Archive,
  Buliding,
  Notification,
  Bubble,
  KyberNetwork,
  Messages2,
  SecuritySafe,
  Calendar1,
  Kanban,
  Profile2User,
  Bill,
  UserSquare,
  ShoppingBag,
  Menu
} from 'iconsax-react';

// icons
const icons = {
  applications: KyberNetwork,
  chat: Messages2,
  calendar: Calendar1,
  kanban: Kanban,
  customer: Profile2User,
  invoice: Bill,
  profile: UserSquare,
  ecommerce: ShoppingBag,
  dashboard: Menu,
  SecuritySafe: SecuritySafe,
  Message2: Message2,
  Bubble: Bubble,
  Archive: Archive,
  Notification: Notification,
  Buliding: Buliding
};

const role = sessionStorage.getItem('role');
// ==============================|| MENU ITEMS - APPLICATIONS ||============================== //

let children = [];

if (role === 'admin') {
  children = [
    // {
    //   id: 'dashboard',
    //   title: <FormattedMessage id="Dashboard" />,
    //   type: 'item',
    //   url: '/admin/dashboard',
    //   icon: icons.dashboard,
    //   breadcrumbs: false
    // },
    {
      id: 'prompt',
      title: 'Prompts',
      type: 'item',
      url: '/admin/prompts',
      icon: icons.chat,
      breadcrumbs: false
    },
    {
      id: 'user',
      title: 'Users',
      type: 'item',
      url: '/admin/users',
      icon: icons.customer,
      breadcrumbs: false
    },
    {
      id: 'allConsultations',
      title: 'Consultation Data',
      type: 'item',
      url: '/admin/allConsultations',
      icon: icons.chat,
      breadcrumbs: false
    },
    {
      id: 'organization',
      title: 'Organisation',
      type: 'item',
      url: '/admin/organizations',
      icon: icons.Buliding,
      breadcrumbs: false
    },
    {
      id: 'requests',
      title: 'Requests',
      type: 'item',
      url: '/admin/requests',
      icon: icons.Notification,
      breadcrumbs: false
    },
    {
      id: 'keys',
      title: 'Api Keys',
      type: 'item',
      url: '/admin/apiKeys',
      icon: icons.SecuritySafe,
      breadcrumbs: false
    },
    // {
    //   id: 'feedback',
    //   title: 'Feedback',
    //   type: 'item',
    //   url: '/admin/feedbacks',
    //   icon: icons.Message2,
    //   breadcrumbs: false
    // },

    {
      id: 'archive',
      title: 'Deleted',
      type: 'collapse',
      icon: icons.Archive,
      children: [
        {
          id: 'archive',
          title: 'Consultations',
          type: 'item',
          url: '/admin/archived-consultations',
          icon: icons.Bubble,
          breadcrumbs: false
        },
        {
          id: 'archived-prompts',
          title: 'Prompts',
          type: 'item',
          url: '/admin/archived-prompts',
          icon: icons.chat,
          breadcrumbs: false
        }
      ]
    }
  ];
} else {
  children = [
    // {
    //   id: 'dashboard',
    //   title: <FormattedMessage id="Dashboard" />,
    //   type: 'item',
    //   url: '/dashboard/default',
    //   icon: icons.chat,
    //   breadcrumbs: false
    // },
    {
      id: 'consultation',
      title: 'Consultation',
      type: 'item',
      url: '/home/consultation',
      icon: icons.chat,
      breadcrumbs: false
    }
  ];
}

const applications = {
  id: 'group-applications',
  title: '',
  icon: icons.applications,
  type: 'group',
  children
};

export default applications;
