// material-ui
import { Box,} from '@mui/material';

// ==============================|| HEADER CONTENT - SEARCH ||============================== //

const Search = () => (
  <Box sx={{ width: '100%', ml: { xs: 0, md: 2 } }}>

  </Box>
);

export default Search;
