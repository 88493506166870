import PropTypes from 'prop-types';
import { createContext, useEffect, useReducer } from 'react';

// third-party
import jwtDecode from 'jwt-decode';

// reducer - state management
import { LOGIN, REGISTER, LOGOUT } from 'store/reducers/actions';
import authReducer from 'store/reducers/auth';

// project-imports
import Loader from 'components/Loader';
import axios from 'utils/axios';
import { useNavigate } from 'react-router';
// constant
const initialState = {
  isLoggedIn: false,
  isInitialized: false,
  user: null
};

const verifyToken = (serviceToken) => {
  if (!serviceToken) {
    return false;
  }
  const decoded = jwtDecode(serviceToken);

  /**
   * Property 'exp' does not exist on type '<T = unknown>(token: string, options?: JwtDecodeOptions | undefined) => T'.
   */
  return decoded.exp > Date.now() / 1000;
};

const setSession = (serviceToken) => {
  if (serviceToken) {
    sessionStorage.setItem('serviceToken', serviceToken);
    axios.defaults.headers.common.Authorization = `Bearer ${serviceToken}`;
  } else {
    sessionStorage.removeItem('serviceToken');
    delete axios.defaults.headers.common.Authorization;
  }
};

// ==============================|| JWT CONTEXT & PROVIDER ||============================== //

const JWTContext = createContext(null);

export const JWTProvider = ({ children }) => {
  const [state, dispatch] = useReducer(authReducer, initialState);
  const navigate = useNavigate();

  useEffect(() => {
    const init = async () => {
      try {
        const serviceToken = sessionStorage.getItem('serviceToken');
        if (serviceToken && verifyToken(serviceToken)) {
          setSession(serviceToken);
          const user = JSON.parse(sessionStorage.getItem('user'));

          dispatch({
            type: LOGIN,
            payload: { user }
          });
        } else {
          dispatch({
            type: LOGOUT
          });
        }
      } catch (err) {
        console.error(err);
        dispatch({
          type: LOGOUT
        });
      }
    };

    init();
  }, []);

  const login = async (userEmail, password) => {
    try {
      const response = await axios.post(
        `/auth/login`,
        {
          email: userEmail,
          password
        },
        {
          headers: { 'Content-Type': 'application/json', Accept: 'application/json' }
        }
      );
      if (response?.data?.user?.role === 'admin' || response?.data?.user?.is2FAEnabled === false) {
        handleSetLoginData(response);
        return response;
      }
      sessionStorage.setItem('qrCode', JSON.stringify(response.data.qrCode));
      sessionStorage.setItem('serviceTokenTemp', response.data.token);
      window.location.pathname = '/auth/code-verification';
    } catch (error) {
      console.error('Error during login:', error);
      throw error;
    }
  };

  const verify2FA = async (code) => {
    try {
      const response = await axios.post(
        '/auth/verify-2fa',
        { code },
        {
          headers: {
            authorization: `Bearer ${sessionStorage.getItem('serviceTokenTemp')}`
          }
        }
      );
      handleSetLoginData(response);
      return response;
    } catch (error) {
      console.error('Error during 2FA verification:', error);
      throw error;
    }
  };

  const handleSetLoginData = (response) => {
    sessionStorage.removeItem('serviceTokenTemp');
    sessionStorage.removeItem('qrCode');
    const serviceToken = response?.data?.token;
    const refreshToken = response?.data?.refreshToken;
    const userId = response?.data?.user?._id;
    const role = response?.data?.user?.role;
    const email = response?.data?.user?.email;
    const firstName = response?.data?.user?.firstName;
    const lastName = response?.data?.user?.lastName;
    setSession(serviceToken);
    sessionStorage.setItem('user', JSON.stringify(response?.data?.user));
    sessionStorage.setItem('is2FAEnabled', response?.data?.user?.is2FAEnabled);
    sessionStorage.setItem('userId', userId);
    sessionStorage.setItem('email', email);
    sessionStorage.setItem('role', role);
    sessionStorage.setItem('refreshToken', refreshToken);
    sessionStorage.setItem('firstName', firstName);
    sessionStorage.setItem('lastName', lastName);
    dispatch({
      type: LOGIN,
      payload: {
        user: response?.data?.user
      }
    });
    window.location.reload();
  };

  const toggle2FA = async (userId) => {
    try {
      const response = await axios.get(`/auth/toggle-2fa/${userId}`);
      return response;
    } catch (error) {
      console.error('Error during 2FA toggle:', error);
      throw error;
    }
  };

  const register = async (firstName, lastName, email, password, role, organization) => {
    const response = await axios.post('/auth/signup', {
      firstName,
      lastName,
      email,
      password,
      role,
      organization
    });
    navigate(`/login`);
    let user = response.data;

    dispatch({
      type: REGISTER,
      payload: {
        user
      }
    });
  };

  const logout = () => {
    sessionStorage.clear();
    setSession(null);
    dispatch({ type: LOGOUT });
  };

  const resetPassword = async () => { };

  const updateProfile = () => { };

  if (state.isInitialized !== undefined && !state.isInitialized) {
    return <Loader />;
  }

  return (
    <JWTContext.Provider value={{ ...state, login, logout, register, resetPassword, updateProfile, verify2FA, toggle2FA }}>
      {children}
    </JWTContext.Provider>
  );
};

JWTProvider.propTypes = {
  children: PropTypes.node
};

export default JWTContext;
