// third-party
import { createSlice } from '@reduxjs/toolkit';

// project-imports
import axiosServices from 'utils/axios';
import { dispatch } from '../index';

// ----------------------------------------------------------------------

const initialState = {
  error: null,
  users: [],
  loading: false
};

// ==============================|| SLICE - CART ||============================== //
const slice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    // HAS ERROR
    hasError(state, action) {
      state.error = action.payload;
    },
    setUsers(state, action) {
      state.users = action.payload;
    },
    setLoading(state, action) {
      state.loading = action.payload;
    }
  }
});

// Reducer
export default slice.reducer;

// export function getAllUsers() {
//   return async () => {
//     try {
//       dispatch(slice.actions.setLoading(true));
//       const response = await axiosServices.get('/user/get-all-users?filter=all');
//       dispatch(slice.actions.setUsers(response.data));
//       dispatch(slice.actions.setLoading(false));
//     } catch (error) {
//       dispatch(slice.actions.hasError(error));
//     }
//   };
// }

// export function updateUser(userId, payload) {
//   return async () => {
//     try {
//       const reponse = await axiosServices.put(`/user/update/${userId}`, payload);
//       return reponse.data;
//     } catch (error) {
//       dispatch(slice.actions.hasError(error));
//     }
//   };
// }

export async function getAllUsers(filter) {
  try {
    const response = await axiosServices.get(`/user/get-all-users?filter=${filter}`)
    return response.data;
  } catch (error) {
    console.error('Error fetching all users:', error);
    throw error;
  }
}

export async function deleteUser(id) {
  try {
    const response = await axiosServices.delete(`/user/delete/${id}`)
    return response.data;
  } catch (error) {
    console.error('Error fetching all users:', error);
    throw error;
  }
}

export const updateUser = async (userId, payload) => {
  try {
    const response = await axiosServices.put(`/user/update/${userId}`, payload);
    return response;
  } catch (error) {
    console.error('Error updating user role', error);
    throw error;
  }
};

export async function getUserConsultations(userId) {
  try {
    const response = await axiosServices.get(`/consultation/getUserConsultations-admin?userId=${userId}`)
    return response.data;
  } catch (error) {
    console.error('Error fetching all users consultations:', error);
    throw error;
  }
}

export async function deleteConsultations(consId) {
  try {
    const response = await axiosServices.delete(`/consultation/specific/${consId}`)
    return response.data;
  } catch (error) {
    console.error('Error deleting consultation:', error);
    throw error;
  }
}

export async function getUserById(userId) {
  try {
    const response = await axiosServices.get(`/user/get-user-by-id/${userId}`)
    return response.data;
  } catch (error) {
    console.error('Error fetching user by id:', error);
    throw error;
  }
}

export async function getDeletedCons() {
  try {
    const response = await axiosServices.get(`/consultation/get-deleted-consultations`)
    return response.data;
  } catch (error) {
    console.error('Error fetching all deleted consultations:', error);
    throw error;
  }
}

export const restoreConsultations = async (consId) => {
  try {
    const response = await axiosServices.put(`/consultation/restore/${consId}`);
    return response;
  } catch (error) {
    console.error('Error restoring consultation', error);
    throw error;
  }
};

export const approveUser = async (userId) => {
  try {
    const response = await axiosServices.put(`/auth/approve-user/${userId}`);
    return response;
  } catch (error) {
    console.error('Error approving user', error);
    throw error;
  }
};

export const rejectUser = async (userId) => {
  try {
    const response = await axiosServices.put(`/auth/reject-user/${userId}`);
    return response;
  } catch (error) {
    console.error('Error rejecting user', error);
    throw error;
  }
};

export const registerUser = async (firstName, lastName, email, role, organization) => {
  try {
    const response = await axiosServices.post('/auth/signup-admin', {
      firstName,
      lastName,
      email,
      role,
      organization
    });
    return response;
  } catch (error) {
    console.error('Error occurred while registering user:', error);
    throw error;
  }
};

export const changeUserPassword = async (userId, payload) => {
  try {
    const response = await axiosServices.put(`/auth/change-password-admin/${userId}`, payload);
    return response;
  } catch (error) {
    console.error('Error updating user password', error);
    throw error;
  }
};

export async function getAllConsultations() {
  try {
    const response = await axiosServices.get(`/consultation/getUserConsultations-admin`)
    return response.data;
  } catch (error) {
    console.error('Error fetching all consultations:', error);
    throw error;
  }
}

export const closeCons = async (consId) => {
  try {
    const response = await axiosServices.put(`/consultation/close-consultation/${consId}`);
    return response;
  } catch (error) {
    console.error('Error closing consultation', error);
    throw error;
  }
};