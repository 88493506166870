import axios from 'axios';

const axiosServices = axios.create({ baseURL: 'https://api.scribotics.ai/api' });
// const axiosServices = axios.create({ baseURL: 'http://localhost:5000/api' });

// ==============================|| AXIOS - FOR MOCK SERVICES ||============================== //

axiosServices.interceptors.response.use(
  (response) => response,
  (error) => {
    if (error.response.status === 401 && !window.location.href.includes('/login')) {
      window.location.pathname = '/login';
    }
    return Promise.reject((error.response && error.response.data) || 'Wrong Services');
  }
);

export default axiosServices;
